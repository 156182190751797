import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ConfirmActionPopup from '../../../../../components/confirmactionpopup/ConfirmActionPopup';
import BackdropLoader from '../../../../../components/loader/BackdropLoader';
import UserVariablesTable from "../../../../../components/uservariablestable/UserVariablesTable";
import {
  editProjectApiCall
} from "../../../redux/actions";

export class VariablesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variables: [],
      popupOpen: false,
      vars: {},
      isGlobalVariableEditable:false
    };
  }

  componentDidMount() {
    var variableInitial = [];

    if (
      this.props.projectDetails &&
      this.props.projectDetails.variables !== undefined
    ) {
      // const variables =
      //   this.props.projectDetails.variables === "" ||
      //   this.props.projectDetails.variables === "[]"
      //     ? []
      //     : this.props.projectDetails.variables;

      const variables =   typeof this.props.projectDetails?.variables !== "object" ? JSON.parse(this.props.projectDetails?.variables ) : this.props.projectDetails?.variables 
      variables &&
        variables !== null &&
        variables.map((projectVar, ind) => {
          let isProtected = false;
          if (projectVar.type === "secret") {
            isProtected = true;
          }
          const _variable = {
            id: ind,
            key: projectVar.key,
            value: projectVar.value,
            isProtected: isProtected,
            isShowPassword: false,
          };
          variableInitial.push(_variable);
        });

      this.setState({
        variables: variableInitial,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var variableInitial = [];

    if (
      nextProps.projectDetails &&
      nextProps.projectDetails.variables !== undefined &&
      nextProps.projectDetails.variables !== this.props.projectDetails.variables
    ) {
      // const variables =
      //   nextProps.projectDetails.variables === "" ||
      //   nextProps.projectDetails.variables === "[]"
      //     ? []
      //     : nextProps.projectDetails.variables;
      const varType = typeof nextProps.projectDetails?.variables
      const variables =  (varType !== "object" && varType !== "undefined")? JSON.parse(nextProps.projectDetails?.variables ) : nextProps.projectDetails?.variables 
      variables &&
        variables !== null &&
        variables.map((projectVar, ind) => {
          let isProtected = false;
          if (projectVar.type === "secret") {
            isProtected = true;
          }
          const _variable = {
            id: ind,
            key: projectVar.key,
            value: projectVar.value,
            isProtected: isProtected,
            isShowPassword: false,
          };
          variableInitial.push(_variable);
        });

      this.setState({
        variables: variableInitial,
      });
    }
  }

  handleSaveVariables = (projectVars) => {
    this.setState({
      vars: projectVars,
      popupOpen: true
    })
    // const payload = {
    //   variables: JSON.stringify(projectVars.user_variables),
    //   id: this.props.projectDetails.id,
    // };

    // this.props.editProjectApiCall(payload, this.props.projectDetails.id);
  };

  handleAgreeHandler = () => {
    const projectVars = this.state.vars
    const payload = {
      variables: projectVars.user_variables,
      id: this.props.projectDetails.id,
    };
    this.setState({
      popupOpen: false,
      isGlobalVariableEditable:false,  
    });

    this.props.editProjectApiCall(payload, this.props.projectDetails.id);

  };

  handleDisAgreeHandler = () => {
    this.setState({
      popupOpen: false,
    });
  };

  toggleEditableFlag = (variableType,explictState) => {

    if (variableType !== "project")  return;
    this.setState({ isGlobalVariableEditable:explictState|| !this.state.isGlobalVariableEditable })
  }

  render() {
    const { projectRole,t } = this.props;
    const { variables, vars, popupOpen } = this.state;
    return (
        <div data-test="main-container">
            <UserVariablesTable
              userVariables={popupOpen ? vars.user_variables : variables}
              systemVariables={[]}
              handleSave={this.handleSaveVariables}
              updateModule={`project`}
              role={projectRole}
              title={t('Projects.VariablesTab.title')}
              supportUserVariable={true}
              open={this.state.popupOpen}
              data-test="var-table"
              disableEdit={!this.state.isGlobalVariableEditable}
              toggleEditableFlag={this.toggleEditableFlag}
            />
            <ConfirmActionPopup
              open={this.state.popupOpen}
              handleAgree={this.handleAgreeHandler}
              handleDisAgree={this.handleDisAgreeHandler}
              message={t('Projects.VariablesTab.message')}
              yesText={t('Projects.VariablesTab.yesText')}
              noText={t('Projects.VariablesTab.noText')}
              data-test="confirm-popup"
            />
            { this.props.editingProject && (
            <div className="loader-center">
                <BackdropLoader message={t('Projects.VariablesTab.updateMessage')} data-test="loader" />
            </div>
        )}
        </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  projectDetails: state.ProjectReducer.projectDetails,
  projectRole: state.ProjectReducer.projectRole,
  editingProject: state.ProjectReducer.editingProject,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch) => {
  return {
    editProjectApiCall: (payload, id) => {
      dispatch(editProjectApiCall(payload, id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withTranslation()(VariablesTab));
